<template>
	<v-sheet class="mt-5 poll" v-if="poll">
		<template v-if="accessGranted">
			<v-alert color="secondary" class="mb-2">
				<p>{{ $t("messages.welcome") }}</p>
				<p>
					Ihr/e Kollege/Kollegin möchte von Ihnen eine Einschätzung zur
					relevanten Thematik (mittels einer Mini-Umfrage) für das digitale
					Zielbild Ihres Krankenhauses erfassen. Bitte beantworten Sie
					hierzu die nachfolgenden Fragen in Bezug auf die Thematik.
				</p>
				<p>Klicken Sie abschließend auf Speichern.</p>
				<p>Ihre Antworten werden der zuständigen Person zurückgespielt</p>
			</v-alert>
			<div class="goal-title">
				<p class="ig-header">
					{{ goal.name }} <br />{{ goalGroup.name }} <br />{{
						department.name
					}}
				</p>
			</div>
			<Questions
				:questions="questions"
				:error="false"
				:data="data"
				@update="updateValue"
			></Questions>
			<p class="text-right">
				<v-dialog v-model="dialog" content-class="mw-small-dialog">
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" large color="primary" class="mr-2">{{
							$t("buttons.save")
						}}</v-btn>
					</template>
					<v-card>
						<v-card-title></v-card-title>
						<v-card-text>
							{{ $t("polls.return_message") }}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="save" color="primary">{{
								$t("buttons.save")
							}}</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</p>
			<saved v-model="saved"></saved>
		</template>
		<div v-else>
			<p v-if="saved">
				{{ $t("polls.thank_you") }}
			</p>
			<v-alert v-else color="secondary">
				{{ $t("survey.access_denied") }}
			</v-alert>
		</div>
	</v-sheet>
</template>

<style lang="less">
.poll .goal-title {
	font-size: 1.3em;
	margin-top: 18px;
}
</style>

<script type="text/javascript">
import Vue from "vue";
import Vuex from "vuex";
import Saved from "@c/notices/Saved";
import Questions from "@c/survey/render/Questions";
// Displays the survey to the poll respondent
// @group Polls
export default {
	name: "Poll",
	props: {
		// the id of the collaboration document
		id: { type: String, required: true },
	},
	data: () => {
		return {
			dialog: false,
			saved: false,
			data: {},
		};
	},
	components: {
		Questions,
		Saved,
	},
	computed: {
		...Vuex.mapState({
			poll(state) {
				var id = this.id;
				return state.collaborations.data[id] || false;
			},
			goal(state) {
				var id = this.poll.goal;
				return state.goals.data[id] || false;
			},
			goalGroup(state) {
				var id = this.poll.goalGroup;
				return state.goalGroups.data[id] || false;
			},
			department(state) {
				var id = this.poll.department;
				return state.departments.data[id] || false;
			},
		}),
		accessGranted() {
			if (!this.poll) {
				return false;
			}
			if (["submitted", "deleted"].indexOf(this.poll.status) > -1) {
				return false;
			}
			return true;
		},
		questions() {
			return this.$store.getters["questions/defaultOrder"];
		},
	},
	methods: {
		// @vuese
		// Sets the value of the response in the data object
		// @arg id - the id of the question being updated
		// @arg value - the user's response
		updateValue(id, value) {
			if (typeof value !== undefined) {
				Vue.set(this.data, id, value);
			}
		},
		// Dispatches save event and then sets the saved value to true
		save() {
			const self = this;
			for (var key in self.data) {
				if (typeof self.data[key] == "undefined") {
					self.data[key] = null;
				}
			}
			self.saved = true;
			self.$store
				.dispatch("collaborations/patch", {
					id: self.id,
					response: self.data,
					status: "submitted",
				})
				.then(() => {
					self.dialog = false;
				})
				.catch(() => {
					self.error = true;
				});
		},
	},
	watch: {
		id: {
			immediate: true,
			handler() {
				this.$store.dispatch("collaborations/fetchById", this.id);
			},
		},
		poll() {
			this.$store.dispatch("goals/fetchById", this.poll.goal);
			this.$store.dispatch("goalGroups/fetchById", this.poll.goalGroup);
			this.$store.dispatch("departments/fetchById", this.poll.department);
		},
	},
	beforeMount() {
		this.$store.commit("LOADING", true);
	},
	mounted() {
		this.$store.commit("LOADING", false);
	},
	created() {
		this.$store.dispatch("questions/openDBChannel");
	},
};
</script>
